const ImprovementGuide = defineComponent({
  render() {
    const jumpLink = () => {
      window.open('https://www.atomm.com/discussion/1099', '_blank');
    };
    return (
      <div class="flex items-center justify-between bg-uni-12 rounded-lg py-3 px-4 mb-4 font-semibold text-uni-6 cursor-pointer" onClick={jumpLink}>
        <div class="flex items-center">
          <icon-font class="text-2xl mr-3" type="icon-Vector" />
          <span class="text-level-4">A fancy cover can attract more clicks.</span>
        </div>
        <div class="flex items-center">
          <span class="text-level-7">Learn more</span>
          <icon-font type="icon-a-30-arrow-right-s-line" class="text-uni-6 tttt" />
        </div>
      </div>
    );
  }
});

export { ImprovementGuide };
