<template>
  <draggable
    :list="fileList"
    :animation="200"
    item-key="uid"
    filter=".forbid"
    :move="onMove"
    :group="dragGroup"
    class="flex flex-wrap gap-3"
    @sort="handleSort"
  >
    <template #item="{ element }">
      <div :class="(element.type === 'video' || element.type === 'aiImage') && !isMobile ? 'forbid' : ''">
        <slot name="image" :element="element"></slot>
      </div>
    </template>
    <template v-if="showUpload" #footer>
      <div key="footer" class="drag-add-box bg-newBg-subtlest" :style="Style">
        <a-upload-dragger
          v-model:file-list="uploadList"
          :support-server-render="true"
          :show-upload-list="false"
          :before-upload="beforeUpload"
          :multiple="multiple"
          :accept="accept"
          :custom-request="() => {}"
          @dragover.prevent="() => {}"
          @change="handleUploadChange"
          @drop="() => false"
        >
          <slot name="upload"></slot>
        </a-upload-dragger>
      </div>
    </template>
  </draggable>
</template>
<script setup lang="ts">
import { handelErrorImg, isValiType } from '@/pages/share/useFormIntegration';
import { type UploadChangeParam, type UploadFile } from 'ant-design-vue';
import { cloneDeep, debounce } from 'lodash-es';
import draggable from 'vuedraggable';
import { ImgLimitSize, VideoLimitWidth } from '../const';
import { FileStatus } from '../interface';
import { getVideoCover, isImageType } from '../utils';
const { handleUploadFile } = useUpload();
const dragGroup = {
  name: 'project',
  pull: 'false',
  put: true,
};
const emits = defineEmits(['fileChange', 'videoChange']);
type PropTypes = {
  width?: number;
  height?: number;
  imgCount?: number;
  imgCountText?: string;
  videoCount?: number;
  videoCountText?: string;
  multiple?: boolean;
  accept?: string;
  maxSize?: number;
};
const props = withDefaults(defineProps<PropTypes>(), {
  width: 84,
  height: 84,
  imgCount: 0,
  videoCount: 0,
  imgCountText: '',
  videoCountText: '',
  multiple: true,
  accept: '.jpg,.jpeg,.png,.gif,.heic',
  maxSize: 15 * 1024 * 1024, // 默认15M
});
const { imgCount, videoCount, imgCountText, videoCountText } = toRefs(props);
const showUpload = computed(() => {
  return fileList.value.length < imgCount.value + videoCount.value;
});
const Style = computed(() => {
  return {
    width: `${props.width}px`,
    height: `${props.height}px`,
  };
});
const videos = computed(() => fileList.value.filter((item: any) => item.type === 'video'));
const images = computed(() => fileList.value.filter((item: any) => item.type !== 'video' && item.type !== 'aiImage'));
const { isMobile } = useScreen();
const fileList = defineModel('fileList', {
  type: Array,
  default: () => [],
});

const uploadList = ref([]);

const handleSort = (e: any) => {
  const newIndex = e.newIndex >= fileList.value.length ? fileList.value.length - 1 : e.newIndex;
  if (images.value.length > imgCount.value) {
    message.error(`Up to  ${imgCountText.value ? imgCountText.value : imgCount.value} images can be uploaded`);
    fileList.value.splice(newIndex, 1);
    return false;
  } else if (videos.value.length > videoCount.value) {
    message.error(`Supported only ${videoCountText.value ? videoCountText.value : videoCount.value} video`);
    fileList.value.splice(newIndex, 1);
    return false;
  }
  fileList.value = cloneDeep(sortFileList(fileList.value));
  emits('fileChange', 'drag');
};
const asyncPool = new AsyncPool(6);
const handleUploadServer = async (uid: string) => {
  try {
    fileList.value.forEach(async (file: any) => {
      if (uid === file.uid) {
        if (file.type.indexOf('video') !== -1) {
          const coverBase64 = await getVideoCover(file.originFileObj);
          Object.assign(file, { thumbnailUrl: coverBase64, type: 'video' });
          uploadVideoCover(coverBase64, file);
        } else {
          Object.assign(file, { thumbnailUrl: fileToBlob(file) });
        }
        asyncPool.run(async () => {
          let contentDisposition = '';
          await handleUploadFile({
            file,
            contentDisposition,
            onProgress: (percent: number) => {
              Object.assign(file, { percent });
            },
            onFinish: (url: string) => {
              file.originFileObj.url = url;
              if (file.type === 'video') {
                Object.assign(file, { videoUrl: url });
                emits('videoChange');
              } else {
                emits('fileChange', 'upload');
              }
            },
            onError: (err: any) => {
              console.log('asyncPool.run ~ err:', err);
            },
          });
        });
      }
    });
  } catch (error) {
    console.error(error);
  }
};
defineExpose({
  handleUploadServer,
});

const uploadVideoCover = (cover: any, file: any) => {
  if (cover) {
    const coverFile = base64ToFile(cover);
    handleUploadFile({
      file: coverFile,
      contentDisposition: '',
      onProgress: (percent: number) => {
        Object.assign(file, { percent });
      },
      onFinish: (url: any) => {
        Object.assign(file, { thumbnailUrl: url });
      },
      onError: (err: any) => {
        console.log('err', err);
      },
    });
  }
};

const beforeUpload = (file: UploadFile) => {
  const { size, type } = file;
  const isImage = isImageType(file);
  const isVideo = type?.indexOf('video') !== -1;
  if (!isValiType(file, props.accept)) {
    message.error('Format do not meet the requirements');
    return Promise.reject();
  } else if ((isImage && size && size > ImgLimitSize) || (isVideo && size && size > VideoLimitWidth)) {
    message.error('Uploaded content exceeded size limit');
    return Promise.reject();
  }
  return true;
};
const formatFileList = (arr: any[]) => {
  const restVideoLen = videoCount.value - videos.value.length;
  const restImgLen = imgCount.value - images.value.length;

  let addVideo = arr.filter((item) => item.type.includes('video'));
  let addImg = arr.filter((item) => !item.type.includes('video'));
  if (addVideo.length && addVideo.length > restVideoLen) {
    message.error(`Supported only ${videoCountText.value ? videoCountText.value : videoCount.value} video`);
    addVideo = addVideo.slice(0, restVideoLen);
  }
  if (addImg.length && addImg.length > restImgLen) {
    message.error(`Up to  ${imgCountText.value ? imgCountText.value : imgCount.value} images can be uploaded`);
    addImg = addImg.slice(0, restImgLen);
  }
  return [...addVideo, ...addImg];
};
const sortFileList = (arr: any) => {
  const videos = arr.filter((item: any) => item.type.includes('video'));
  const images = arr.filter((item: any) => !item.type.includes('video') && item.type !== 'aiImage');
  const aiImages = arr.filter((item: any) => item.type === 'aiImage');
  return [...aiImages, ...videos, ...images];
};
let restFileList: UploadFile[] = [];
const handleUploadChange = debounce(async (info: UploadChangeParam<UploadFile<any>>) => {
  if (!info.file.status) {
    return false;
  }
  let addList = info.fileList
    .filter((item: any) => isValiType(item, props.accept))
    .filter((item: any) => {
      const imgExceedLimit = item.type?.includes('image') && item?.size > ImgLimitSize;
      const videoExceedLimit = item.type?.includes('video') && item?.size > VideoLimitWidth;
      return !restFileList.find((file) => file.uid === item.uid) && !imgExceedLimit && !videoExceedLimit;
    })
    .map((item) => {
      return {
        ...item,
        thumbnailUrl: fileToBlob(item),
      };
    });
  // console.log('addList :>> ', addList);
  restFileList = [...info.fileList];
  addList = await handelErrorImg(formatFileList(addList));
  // console.log('addList :>> ', addList);
  if (addList.length) {
    fileList.value = sortFileList([...(fileList.value as any[]), ...addList]);

    nextTick(() => {
      addList.forEach(async (item) => {
        await handleUploadServer(item.uid || '');
      });
    });
  }
}, 500);
const onMove = (e: any) => {
  if (videos.value.length && e?.relatedContext?.element?.type === 'video') {
    return false;
  }
  return true;
};
const addOfflineListener = () => {
  window.addEventListener('offline', handleOfflineFileStatus);
};

const handleOfflineFileStatus = () => {
  fileList.value.forEach((file: any) => {
    if (file.status === FileStatus.Uploading) {
      Object.assign(file, { status: FileStatus.Error });
    }
  });
};

onMounted(() => {
  addOfflineListener();
});
onUnmounted(() => {
  window.removeEventListener('offline', handleOfflineFileStatus);
});
</script>
