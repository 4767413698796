<template>
  <div>
    <ImprovementGuide @click="handleGuidClick" />
    <div class="mb-8 flex mobile:flex-col">
      <a-form-item :validate-on-rule-change="false" name="cover" class="cover-item">
        <CoverUpload v-model:coverList="coverList" :is-ai-editor="isAiEditor" />
      </a-form-item>

      <div class="pl-4 pt-4">
        <div class="pb-4 text-level-4 text-text-tertiary">
          Upload videos and images to fully showcase what you've created.Or Paste YouTube
          <a @click="handleAddUrl">URL</a>
          <a-tooltip>
            <template #title>
              <span>
                Max. size:
                <br />
                - 20 images, each ≤ 15 MB
                <br />
                - 1 video, ≤ 70 MB
              </span>
            </template>
            <icon-font class="ml-1 text-text-tertiary hover:text-black-hover" type="icon-question-line" />
          </a-tooltip>
        </div>
        <ImgUpload
          ref="imgUploadRef"
          v-model:file-list="projectFileList"
          :accept="imgAccept"
          :img-count="20"
          :video-count="1"
          video-count-text="one"
          :width="IMG_SIZE"
          :height="IMG_SIZE"
          @file-change="handleFileChange"
        >
          <template #image="{ element }">
            <ImgInfo
              :data="element"
              :width="IMG_SIZE"
              :height="IMG_SIZE"
              @delete="handleFileDeleteClick(element)"
              @preview="handlePreview(element)"
              @preview-video="handlePreviewVideo(element)"
              @refresh="handleRefresh(element)"
            />
          </template>
          <template #upload>
            <div class="icon-add-box">
              <icon-font class="text-text-tertiary" type="icon-add-line" />
            </div>
          </template>
        </ImgUpload>
      </div>
    </div>
  </div>
  <VideoUpload v-model:visible="videoModal" @add-video="handleAddVideo" />
  <client-only>
    <CropCover ref="cropRef" @finish="handleFinishCrop" />
  </client-only>
  <client-only>
    <VideoPlayer ref="videoRef" />
  </client-only>
</template>
<script setup lang="ts">
import { handlePictureSuccessUpload, handleSensorEditClick } from '@/components/share/utils';
import type { UploadFile } from 'ant-design-vue';
import { ImageType, VideoType } from '../../const';
import CoverUpload from '../CoverUpload.vue';
import CropCover from '../CropCover.vue';
import ImgUpload from '../ImageUpload.vue';
import ImgInfo from '../ImgInfo.vue';
import VideoPlayer from '../VideoPlayer.vue';
import VideoUpload from '../VideoUpload.vue';
import { ImprovementGuide } from './InfoComp';

const IMG_SIZE = 72;

const { handleUploadFile } = useUpload();
const imgAccept = `${VideoType},${ImageType}`;

const cropRef = ref();

const videoModal = ref(false);

const coverList = defineModel('coverList', { type: Array });

const projectFileList: any = defineModel('projectFileList', { type: Array });

const isAiEditor = computed(() => {
  return !!projectFileList.value.filter((item: any) => item.type === 'aiImage').length;
});
const videoRef = ref();
const imgUploadRef = ref();

// watch(projectFileList, () => {
//   if (projectFileList.value.length > MAX_COUNT) {
//     message.error('Up to 20 images can be uploaded');
//     projectFileList.value = projectFileList.value.slice(0, MAX_COUNT);
//   }
// });

const handleGuidClick = () => {
  handleSensorEditClick({ name: 'Media' });
};
const handleFileChange = (type = 'upload') => {
  handlePictureSuccessUpload('showcase small picture', type);
};

const handleFileDeleteClick = (element: any) => {
  const elementIndex = projectFileList.value.findIndex((item: any) => item.uid === element.uid);

  elementIndex !== -1 && projectFileList.value.splice(elementIndex, 1);
};
const handlePreview = (element: any) => {
  if (element?.originFileObj?.url || element?.url) {
    cropRef.value?.open(element, false);
  } else {
    message.warning('Image is loading');
  }
};

const handlePreviewVideo = (element: any) => {
  if (element?.videoUrl) {
    videoRef.value?.open(element.videoUrl, element.name);
  }
};

const handleRefresh = (element: any) => {
  imgUploadRef.value?.handleUploadServer(element.uid);
};

const handleAddUrl = () => {
  const video = projectFileList.value.find((item: any) => item.type === 'video');
  if (video) {
    message.error('Supported only one video');
  } else {
    videoModal.value = true;
  }
};
const handleAddVideo = (videoItem: any) => {
  const video = projectFileList.value.find((item: any) => item.type === 'video');
  if (!video) {
    // 放置顺序：aimake,video,others
    const aiImage = projectFileList.value.find((item: any) => item.type === 'aiImage');
    const otherFiles = projectFileList.value.filter((item: any) => item.type !== 'aiImage');
    projectFileList.value = [...(aiImage ? [aiImage] : []), videoItem, ...otherFiles];
  } else {
    message.error('Supported only one video');
  }
};
const asyncPool = new AsyncPool(6);
const handleFinishCrop = (blobData: Blob, file: UploadFile) => {
  let cropFile: any = {};
  cropFile = projectFileList.value.find((item: any) => item.uid === file.uid);
  Object.assign(cropFile, { url: fileToBlob(blobData), percent: 0 });
  asyncPool.run(async () => {
    let contentDisposition = '';
    await handleUploadFile({
      file: blobData,
      contentDisposition,
      onProgress: (percent: number) => {
        Object.assign(cropFile, { percent });
      },
      onFinish: (url: string) => {
        Object.assign(cropFile, { url });
        // imagesChange();
      },
      onError: () => {},
    });
  });
};
</script>
<style scoped lang="less">
.upload-box {
  :deep(.ant-upload) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
.drag-add-box {
  position: relative;
  top: -3px;
}
.icon-add-box {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  transition: all 0.2s;
}
:deep(.ant-upload-wrapper .ant-upload-drag:not(.ant-upload-disabled):hover) {
  border-color: #353538;
  .icon-add-box {
    span {
      color: #191919;
    }
  }
}
:deep(.ant-upload-wrapper .ant-upload-drag) {
  background: transparent;
}

.form-content {
  .ant-form-item.cover-item {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 992px) {
  .upload-box {
    width: 100%;
  }
}
</style>
