<template>
  <div id="project-layout">
    <div v-if="isPreview" class="detail-wrap">
      <div class="detail-wrap-content">
        <div
          :class="'sticky top-[64px] z-20 flex w-full items-center justify-center gap-[24px] bg-background-3 py-1.5 font-bold text-status-alert mobile:top-[66px] mobile:flex-col mobile:gap-[8px] mobile:py-2'"
        >
          <div :class="'mobile:px-5'">This is just a preview. Your content hasn't been published yet.</div>
          <div
            :class="'rounded border border-solid border-status-alert px-2 py-0.5 hover:cursor-pointer hover:bg-status-alert hover:text-[#fff]'"
            @click="handleClosePreviewClick"
          >
            Back to Edit
          </div>
        </div>
        <a-row justify="center" class="bg-[#fff]">
          <a-col flex="1200px" :class="'detail-page-preview-box relative bg-[#f4f4f4]'">
            <a-row justify="center">
              <a-col :span="24" :xs="24" :sm="24" :md="24" :lg="24">
                <slot name="preview"></slot>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </div>
    </div>
    <div v-show="!isPreview" class="project-layout">
      <div class="bg-[#fff]">
        <div class="mx-auto flex max-w-[800px] items-center justify-between gap-3 py-6 mobile:px-4 mobile:py-3">
          <div>
            <div class="mb-2 text-[24px] font-semibold leading-[1] mobile:text-[24px]">
              {{ title }}
            </div>
            <div v-if="type !== 'bundle'" class="tip leading-[1]">
              <span class="tip-title text-text-tertiary mobile:text-[12px]" @click="handleGuidelineClick">
                <icon-font class="mr-1 text-[20px] mobile:text-[16px]" type="icon-question-line" />
                Read me first
              </span>
            </div>
          </div>
          <df-btn v-if="isSaleMaker" type="tertiary" @click="changeEditor">
            <icon-font type="icon-switch" class="!text-[24px] mobile:!text-[16px]" />
            Switch {{ type == 'project' ? 'Bundle' : 'Template' }}
          </df-btn>
        </div>
      </div>
      <div v-if="showAiTips && type == 'project'" class="tips-bg w-[100vw] py-3">
        <div class="mx-auto flex max-w-[800px] gap-2 text-[14px] mobile:px-6">
          <exclamation-circle-filled class="text-[20px] text-status-alert" />
          <span class="flex-1 text-[#1a1a1a] mobile:text-[12px]">
            Use your xTool machine to process your image into a finished product, and don't forget to share it when you're done! You can continue
            editing your unfinished post in 'Step 2' on the
            <a href="/activity/ai">contest page</a>
            .
          </span>
          <icon-font type="icon-close-line" class="cursor-pointer text-[24px]" @click="clickCloseTips" />
        </div>
      </div>

      <div class="affix-wrap relative mx-auto max-w-[800px] pb-[72px] mobile:pt-2">
        <a-spin :spinning="initLoading">
          <slot></slot>
        </a-spin>
        <a-affix
          :key="affixKey"
          :offset-top="isMobile ? undefined : 164"
          :offset-bottom="isMobile ? 126 : undefined"
          class="absolute -right-[186px] top-4 z-[40] mobile:bottom-0 mobile:right-3 mobile:top-auto"
        >
          <div v-if="needStep" class="">
            <div class="pb-8 mobile:pb-0">
              <share-project-step-header />
            </div>
          </div>
          <LibraryPendant :id="GuideIds.mediaLibrary" class="mobile:hidden" @click="handleLibraryLick" />
        </a-affix>
        <!-- <a-affix :offset-bottom="120" class="absolute bottom-10 right-3 hidden mobile:block">
          <div v-if="needStep" class="">
            <div class="pb-8">
              <share-project-step-header />
            </div>
          </div>
        </a-affix> -->
      </div>
      <div class="fixed bottom-0 z-[1000] h-[64px] w-full bg-[#fff] shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] mobile:px-6">
        <div class="mx-auto flex h-full max-w-[800px] items-center justify-between">
          <div class="mobile:hidden">
            <span v-if="time > 0" class="save-text text-[14px] text-text-tertiary">
              {{ saveLoading ? 'Saving...' : `Saved ${time} min${time > 1 ? 's' : ''} ago` }}
              <a href="javascript:;" @click="goDraft">My Draft</a>
            </span>
          </div>
          <div class="flex items-center gap-3 mobile:w-full">
            <df-btn v-if="!isPublished" type="tertiary" @click="clickSave">
              <icon-font type="icon-Update" class="font-sans text-[20px] mobile:text-[18px]" />
              Save
            </df-btn>
            <df-btn type="tertiary" :loading="submitLoading" :disabled="initLoading" @click="handlePreviewClick">
              <icon-font type="icon-eye-line" class="text-[20px] mobile:text-[18px]" />
              Preview
            </df-btn>

            <df-btn
              type="primary"
              class="ml-auto mobile:!pe-[26.5px] mobile:!ps-[26.5px]"
              :disabled="initLoading"
              :loading="submitLoading"
              @click="clickNext"
            >
              Submit
            </df-btn>
          </div>
        </div>
      </div>
    </div>
    <a-modal v-model:open="saleErrorModal" title="" ok-text="OK" :keyboard="false" :closable="false" :mask-closable="false" style="top: 250px">
      <div class="absolute -top-[118px] left-0 w-full">
        <img :src="captain" class="absolute inset-x-1/2 -ml-[75px] w-[150px]" />
      </div>
      <div class="mt-5">
        Sorry! The 'Create Premium Bundle' feature is only available to users on the whitelist. If you're interested, you can send an email to
        designfind@xtool.com. 
      </div>
      <template #footer>
        <a-button type="primary" @click="handleSaleErrorModal">OK</a-button>
      </template>
    </a-modal>
    <DraftModal v-model:visible="showDraft" @close="handleClose" />
  </div>
</template>

<script setup lang="ts">
import captain from '@/assets/img/captain.png';
import DraftModal from '@/components/share/create/DraftModal.vue';
import { handleSensorEditClick } from '@/components/share/utils';
import { useAuth } from '@/pages/share/project/hook';
import { useUserInfo } from '@/stores/user';
import { ExclamationCircleFilled } from '@ant-design/icons-vue';
import { GuideIds } from '~/components/share/project/type';
import { useShareProject } from '~/stores/shareProject.js';
import { ModeType } from '~/types/share';
import LibraryPendant from '../common/media-library/Pendant.vue';
import { useAITips } from './hook.js';
import { FormIds } from './type';

type PropType = {
  needStep?: boolean;
  type: string;
  saveLoading?: boolean;
  isPublished?: boolean;
  submitLoading?: boolean;
  initLoading?: boolean;
  hasUserEdited: boolean;
  time: number;
  isChangeRouter: boolean;
  title?: string;
  mode: ModeType;
};

const props = withDefaults(defineProps<PropType>(), {
  title: 'Create a New Template',
  needStep: true,
  saveLoading: false,
  isPublished: false,
  submitLoading: false,
  isChangeRouter: false,
  initLoading: false,
  hasUserEdited: false, // 是否手动编辑过
});

const emit = defineEmits(['emitNext', 'emitSave']);
const isChangeRouter = defineModel('isChangeRouter', { type: Boolean, default: false });
const { saleErrorModal, handleSaleErrorModal } = useAuth(isChangeRouter);
const { showAiTips, clickCloseTips } = useAITips();
const useProjectStore = useShareProject();
const route = useRoute();
const { isMobile } = useScreen();
const { sensorEditorClick, sensorsPublishEditorBottomClick } = useSensors();
const userInfoStore = useUserInfo();
const isSaleMaker = computed(() => userInfoStore.isSaleMaker);

const guideLink = {
  'project': 'https://www.atomm.com/discussion/1100',
  'design': 'https://www.atomm.com/discussion/1082',
} as any;
const handleGuidelineClick = () => {
  const { id } = route.query;
  sensorEditorClick({
    element_name: 'Read me first',
    sevice_type: props.type,
    content_type: String(props.mode),
    content_id: Number(id),
  });
  window.open(guideLink[props.type], '_blank');
};
const handleLibraryLick = () => {
  handleSensorEditClick({ name: 'Media' });
};

const clickNext = () => {
  // 从模板创建的案例，未手动修改过，弹出提示
  const { source } = route.query;
  handleSensorBottomClick('publish');
  if (!props.hasUserEdited && source === 'copyTemplate') {
    Modal.confirm({
      wrapClassName: 'editor-del-modal',
      content:
        'Please note that any copies you create must be modified. Directly publishing unmodified content may result in removal. Are you sure you want to publish it?',
      cancelText: 'No',
      okText: 'Yes',
      keyboard: false,
      transitionName: '',
      onOk: () => {
        emit('emitNext');
      },
      onCancel: () => {
        // console.log('cancel');
      },
    });
  } else {
    emit('emitNext');
  }
};
const clickSave = () => {
  if (props.saveLoading || props.submitLoading) {
    return;
  }
  emit('emitSave');
  handleSensorBottomClick('save');
};
const { sensorsPreviewMakingClickEvent } = useSensors();
const isPreview = ref(false);
const affixKey = ref(0);
const pb = computed(() => {
  return isPreview.value ? '0' : '60px';
});
const handlePreviewClick = () => {
  isPreview.value = true;
  window.scrollTo(0, 0);
  const route = useRoute();
  const { fullPath, query } = route;
  const type = fullPath.split('/')[2]?.split('?')[0] as string;
  sensorsPreviewMakingClickEvent({ is_success: true, making_type: type, content_type: query.mode === ModeType.Sale ? ModeType.Sale : ModeType.Free });
  handleSensorBottomClick('preview');
  if (props.type === 'project') {
    useProjectStore.changeStep(FormIds.BasicInfo);
  }
};
const handleClosePreviewClick = () => {
  isPreview.value = false;
  affixKey.value += 1;

  window.scrollTo(0, 0);
};
const showDraft = ref(false);
const handleClose = () => {
  showDraft.value = false;
};
const goDraft = () => {
  showDraft.value = true;
  handleSensorBottomClick('draft');
};
const router = useRouter();
const changeEditor = () => {
  Modal.confirm({
    content: `Switch to ${props.type === 'project' ? 'Bundle' : 'Template'}, confirm?`,
    cancelText: 'Cancel',
    okText: 'Ok',
    closable: true,
    onOk: async () => {
      if (props.type === 'project') {
        window.open('/share/bundle', '_blank');
      } else if (props.type === 'bundle') {
        isChangeRouter.value = true;
        router.push({ path: '/share/project' });
      }
    },
  });
};
const handleSensorBottomClick = (element: string) => {
  sensorsPublishEditorBottomClick({
    click_element: element,
    is_free: props.mode === ModeType.Free,
  });
};
</script>

<style scoped lang="less">
.detail-wrap {
  background-color: theme('colors.background.2');
  min-height: calc(100vh - 230px);
  padding-bottom: v-bind(pb);
  position: relative;
  .detail-wrap-content {
    position: relative;
    width: 100%;
    .detail-page-preview-box {
      :deep(.detail-inner-content-box) {
        .detail-inner-content {
          width: 100% !important;
        }
        .sticky-tab-bar {
          position: static;
        }
      }
    }
  }
}

.header {
  background-color: #f0f0f0;
  padding: 20px;
}

.tip {
  font-size: 12px;
  display: flex;
  align-items: center;
  .tip-title {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .icon {
    margin-right: 4px;
    font-size: 20px;
  }
}
:deep(.ant-card-bordered) {
  border: none;
}
:deep(.ant-affix) {
  z-index: 200;
}
.tips-bg {
  background: linear-gradient(0deg, rgba(255, 124, 35, 0.1) 0%, rgba(255, 124, 35, 0.1) 100%), #fff;
}
</style>
<style lang="less">
.editor-del-modal {
  .ant-modal-confirm .ant-modal-confirm-body {
    flex-wrap: nowrap;
  }
  .ant-btn-primary {
    background-color: #1a1a1a;
  }
  .ant-btn-default {
    border: 1px solid rgba(26, 26, 26, 0.1);
  }
  .ant-btn-default:not(:disabled):hover {
    color: #1a1a1a;
    border-color: #1a1a1a;
  }
  .ant-btn-primary:not(:disabled):hover {
    border-color: theme('colors.black.hover');
    background-color: theme('colors.black.hover');
  }
}
</style>
