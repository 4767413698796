<template>
  <a-form-item :name="Name">
    <template #label>
      <span>
        {{ label }}
        <a-tooltip title="The title of your template. It will be displayed on the template page and search results.">
          <icon-font class="ml-1 text-text-tertiary hover:text-black-hover" type="icon-question-line" />
        </a-tooltip>
      </span>
    </template>
    <df-input v-model:value.lazy.trim="title" :placeholder="PlaceHolder" :maxlength="150" show-count />
    <div v-if="initAi" class="mt-2.5 flex items-center gap-2 text-level-4 text-uni-13">
      <img :src="aiLoading ? shareAiGif : shareAiImg" alt="" width="20" height="20" />
      <span v-if="aiLoading">AI Generating...</span>
      <template v-else-if="!!aiTitle">
        <span>{{ aiTitle }}</span>
        <div
          :class="'btn-border flex h-[22px] w-[46px] cursor-pointer items-center justify-center rounded-full text-level-7 font-semibold hover:bg-background-3'"
          @click="handleUseAiTitle"
        >
          Use
        </div>
      </template>
    </div>
    <div v-else class="mt-2.5 flex items-center gap-2 text-level-4 text-[#565656]">
      <img :src="shareAiImg" alt="" width="20" height="20" />
      <span>When cover uploaded, AI can suggests titles!</span>
    </div>
  </a-form-item>
</template>

<script setup lang="ts">
import { handleSensorEditClick } from '@/components/share/utils';
import type { CoverAutoTag } from '@/types/share';
const props = defineProps<{
  label: string;
  coverAutoTag: CoverAutoTag;
}>();
const emit = defineEmits(['handleValidate']);
const { initAi, loading: aiLoading, title: aiTitle, shareAiImg, shareAiGif } = toRefs(props.coverAutoTag);

const title = defineModel('title', { type: String, default: '' });
const handleUseAiTitle = () => {
  title.value = aiTitle.value;
  emit('handleValidate');
  handleSensorEditClick({ name: 'AI title Use', content: aiTitle.value });
};
const Name = 'title';
const PlaceHolder = 'Tell us what do you make';
</script>
<style scoped>
.btn-border {
  border: 1px solid #dadada;
}
</style>
